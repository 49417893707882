@import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;0,500;0,600;1,100;1,200;1,500;1,600&display=swap');

body {
  font-family: 'Work Sans', sans-serif;
  background: #bcbcbc;
  color: rgb(32,32,32);
}

a {
  color: rgb(32,32,32);
  &:hover {
    color:black;
  }
}

.tile-collection {
  width:100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
  * {
    display:flex;
    justify-content: flex-start;
    flex: 1 1 100%;
    flex-wrap: wrap;
  }
  .tile {
    display:flex;
    //flex-direction:column;
    //min-width:200px;
    padding:0.25em;
    //font-size:2.5rem;
    flex: 1 1 25%;
    margin: 0.25em;;
    align-self: stretch;
    >.tile {
      border:1px dashed #333;
    }
  }
}

.container {
  width:66%;
  margin: auto auto;
  display: flex;
  flex-direction: column;

  > footer {
    align-self: flex-end;
    bottom: 0;
    position: absolute;
    width: 66%;
    text-align: right;
  }
}

#logo {
  margin:auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s all;
  &.docked {
    flex-direction:row;
    canvas {
      zoom:0.65;
      &~div {
        padding-left:1rem;
        flex: 1 0 auto;
        h1,h2 {
          text-align: left;
        }
      }
    }
    nav {
      align-self: center;
    }
  }
  canvas {
    width:210px;
    display:block;
    position:relative;
  }
  h1,h2 {
    text-align: center;
  }
  h1 {
    font-weight:600;
    font-size:1.6rem;
    line-height:0;
  }
  h2 {
    font-weight: 300;
    font-size: 1.45rem;
    line-height:1em;
  }
  ul, li {
    list-style: none;
    padding:0;
  }
  nav {
    ul {
      display:flex;
      flex-direction:row;
      align-items:center;
    }
    li {
      padding:0 1em;
      font-weight:300;
    }
  }
}

.blog-snippet-img {
  img {
    width:100%;
  }
}