@import url("https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100;0,300;0,500;0,600;1,100;1,200;1,500;1,600&display=swap");
body {
  font-family: "Work Sans", sans-serif;
  background: #bcbcbc;
  color: #202020;
}
a {
  color: #202020;
}
a:hover {
  color: black;
}
.tile-collection {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: stretch;
  align-items: center;
}
.tile-collection * {
  display: flex;
  justify-content: flex-start;
  flex: 1 1 100%;
  flex-wrap: wrap;
}
.tile-collection .tile {
  display: flex;
  padding: 0.25em;
  flex: 1 1 25%;
  margin: 0.25em;
  align-self: stretch;
}
.tile-collection .tile > .tile {
  border: 1px dashed #333;
}
.container {
  width: 66%;
  margin: auto auto;
  display: flex;
  flex-direction: column;
}
.container > footer {
  align-self: flex-end;
  bottom: 0;
  position: absolute;
  width: 66%;
  text-align: right;
}
#logo {
  margin: auto auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: 0.3s all;
}
#logo.docked {
  flex-direction: row;
}
#logo.docked canvas {
  zoom: 0.65;
}
#logo.docked canvas ~ div {
  padding-left: 1rem;
  flex: 1 0 auto;
}
#logo.docked canvas ~ div h1, #logo.docked canvas ~ div h2 {
  text-align: left;
}
#logo.docked nav {
  align-self: center;
}
#logo canvas {
  width: 210px;
  display: block;
  position: relative;
}
#logo h1, #logo h2 {
  text-align: center;
}
#logo h1 {
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 0;
}
#logo h2 {
  font-weight: 300;
  font-size: 1.45rem;
  line-height: 1em;
}
#logo ul, #logo li {
  list-style: none;
  padding: 0;
}
#logo nav ul {
  display: flex;
  flex-direction: row;
  align-items: center;
}
#logo nav li {
  padding: 0 1em;
  font-weight: 300;
}
.blog-snippet-img img {
  width: 100%;
}
/*# sourceMappingURL=index.5af1ed7d.css.map */
